@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7W0Q5n-wU.woff2)
      format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
      U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  
  body {
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;
    min-height: 90vh;
    
  }

  .div_text_animado{
    display: flex;
    width: 95%;
    margin: auto;
    padding-bottom: 40px;
  }

  .text_left {
    padding-left: 20px;
    padding-right: 20px;
    font-size:medium;
    text-align: left;
    font-weight: 400;
  }

  .text_center {
    padding-top: 20px;
    text-align: center;
    text-decoration: underline;
  }
  
  section {
    box-sizing: border-box;
    width: 90%;
    height: 45vh;
    display: flex;
    justify-content: center;
    overflow: hidden;
    background:  #ffffff;
  }
  
  section:nth-child(2) {
    background: #dddddd;
  }
  
  section:nth-child(3) {
    background: #ffffff;
  }
  
   
  section span {
    display: block;
    transform: translateX(-100px);
    opacity: 0;
  }
  
  .titulo {
    text-decoration: underline;
  }
  
  .icon_logo{
    margin: auto;
    text-align: center;
    width: 70px;
    height: 70px;
    padding: 10px;
    border-radius: 50px;
    background-color: #fdce54;
  }

  .div_icon_logo {
    width: 100%;
    margin-top: 10px;
    display: flex;
  }

  @media only screen and (max-width: 540px) { 

    .div_text_animado {
        flex-direction: column;
    }

    section {
      padding-top: 10px;
      padding-bottom: 10px;
    }
    
    section:nth-child(2) {
      padding-top: 10px;
      padding-bottom: 10px;
      min-height: 300px;
    }
    
    section:nth-child(3) {
      padding-top: 10px;
      padding-bottom: 10px;
    }

  }