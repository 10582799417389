.area{
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-content: center;
}

.banner_area{
    width: 100%;
    background-color:#2b2727;
}

.body_top{ 
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: top;
}

.banner {
    margin: auto;
    width: 100%;
    max-height: 550px;
    text-align: center;
    
}

.body_area {
   top: 0;
   left: 0;
}

.body_top_img{
    width: 30%;
    height: auto;
}

.body_top_text{
    width: 100%;
    height: auto;
    border: none;
}

.body_bottom {
    margin: auto;
    width: 80%;
}

.imgBannerLeft {
    width: 100%;
}